import type { MaskInputOptions } from 'maska';

export const durationMaskOptions: MaskInputOptions = {
  mask: (value: string) =>
    value.startsWith('0') || value.startsWith('1') ? 'H#:M#' : 'Hh:M#',
  eager: true,
  tokens: {
    '#': { pattern: /[0-9]/ },
    H: { pattern: /[0-2]/ },
    h: { pattern: /[0-3]/ },
    M: { pattern: /[0-5]/ },
  },
};

export const dateMaskOptions = (format: string): MaskInputOptions => ({
  mask: format,
  eager: true,
  tokens: {
    d: { pattern: /[0-9]/ },
    M: { pattern: /[0-9]/ },
    y: { pattern: /[0-9]/ },
  },
});

export const dateTimeMaskOptions = (format: string): MaskInputOptions => ({
  mask: (value: string) => {
    const [, timeValue, amPm] = value.split(' ');
    const [dateFormat] = format.split(' ');

    return `${dateFormat} ${timeValue?.startsWith('0') || timeValue?.startsWith('1') ? 'H#:m#' : 'Hh:m#'}${amPm ? ' Aa' : ''}`;
  },
  eager: true,
  tokens: {
    d: { pattern: /[0-9]/ },
    M: { pattern: /[0-9]/ },
    y: { pattern: /[0-9]/ },
    '#': { pattern: /[0-9]/ },
    H: { pattern: /[0-2]/ },
    h: { pattern: /[0-3]/ },
    m: { pattern: /[0-5]/ },
    A: { pattern: /[AP]/ },
    a: { pattern: /M/ },
  },
});
